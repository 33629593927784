<template>
  <div>
    <el-form-item label="Тип">
      <el-select
        v-model="infoLocal.type"
        size="mini"
        @change="valueChanged"
      >
        <el-option label="КПЭ" :value="1"></el-option>
        <el-option label="ПО" :value="2"></el-option>
      </el-select>
    </el-form-item>


    <el-form-item label="Период действия">
      С
      <el-date-picker
        v-model="infoLocal.date_start"
        style="width: 150px"
        size="small"
        type="date"
        value-format="yyyy-MM-dd"
        format="dd.MM.yyyy"
        :picker-options="{firstDayOfWeek: 1,}"
        @change="valueChanged"
      >
      </el-date-picker>
      &nbsp;по&nbsp;
      <el-date-picker
        v-model="infoLocal.date_end"
        style="width: 150px"
        size="small"
        type="date"
        value-format="yyyy-MM-dd"
        format="dd.MM.yyyy"
        :picker-options="{firstDayOfWeek: 1,}"
        @change="valueChanged"
      >
      </el-date-picker>
    </el-form-item>

  </div>

</template>

<script>
import {mapGetters} from "vuex";
import ClickToEdit from "@/components/ClickToEdit.vue";

export default {
  name: "cards-create-info",
  components: {},

  props: {
    info: {type: Object, required:true},
    card: {type: Object},
  },

  watch: {
    'card.year': function (newVal) {
      this.infoLocal.date_start = (newVal+'-01-01');
      this.infoLocal.date_end = (newVal+'-12-31');
      this.valueChanged();
    },
    'info.type': function(newVal){
      this.infoLocal.type = newVal;
    }
  },
  computed: {},
  data() {
    return {
      infoLocal: {
        date_start: null,
        date_end: null,
        type: null,
      },
    }
  },
  mounted() {
    this.infoLocal.date_start = (this.card.year+'-01-01');
    this.infoLocal.date_end = (this.card.year+'-12-31');
    this.valueChanged();
  },
  methods: {
    valueChanged(){
      this.$emit('update:info', this.infoLocal);
    }
  }
}
</script>


<style lang="scss">

</style>